import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectCreative } from 'swiper';
import 'swiper/css';
import "swiper/css/effect-creative"

import image1 from '../images/slider/DSL03578m300.JPG';
import image2 from '../images/slider/DSL03582m300.JPG';
import image3 from '../images/slider/DSL03591m300.JPG';

SwiperCore.use([Autoplay, EffectCreative]);

const PhotoSlider = () => {
	return (
		// <>
		// 	<Swiper slidesPerView={3} spaceBetween={30} pagination={{
		// 	"clickable": true
		// 	}} className="mySwiper">
		// 	<SwiperSlide>Slide 1</SwiperSlide><SwiperSlide>Slide 2</SwiperSlide><SwiperSlide>Slide 3</SwiperSlide><SwiperSlide>Slide 4</SwiperSlide><SwiperSlide>Slide 5</SwiperSlide><SwiperSlide>Slide 6</SwiperSlide><SwiperSlide>Slide 7</SwiperSlide><SwiperSlide>Slide 8</SwiperSlide><SwiperSlide>Slide 9</SwiperSlide>
		// </Swiper>
		// </>
		<Swiper
			grabCursor={false}
			effect={'fade'}
			// effect={'creative'}
			// creativeEffect={{
			// 	"prev": {
			// 		"shadow": true,
			// 		"translate": [
			// 			0,
			// 			0,
			// 			-400
			// 		]
			// 	},
			// 	"next": {
			// 		"translate": [
			// 			"100%",
			// 			0,
			// 			0
			// 		]
			// 	}
			// }}
			slidesPerView={3}
			spaceBetween={0}
			loop={true}
			centeredSlides={true}
			autoplay={{
				"delay": 5000,
				"disableOnInteraction": true
			}}
			className="mySwiper"
		>
			<SwiperSlide><img src={image1} alt="" className='border-r-2 border-l-2 border-dark-yellow'/></SwiperSlide>
			<SwiperSlide><img src={image2} alt="" className='border-r-2 border-l-2 border-dark-yellow'/></SwiperSlide>
			<SwiperSlide><img src={image3} alt="" className='border-r-2 border-l-2 border-dark-yellow'/></SwiperSlide>
		</Swiper>

	);
}

export default PhotoSlider;