const PUBLIC_URL = "https://teskandiplebania.hu/";

export const photos = [
	{
		src: PUBLIC_URL + "gallery/bode/Bode-1.jpg",
		width: 2,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/bode/Bode-2.jpg",
		width: 3,
		height: 2
	},
	{
		src: PUBLIC_URL + "gallery/bode/Bode-3.jpg",
		width: 2,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/bode/Bode-4.jpg",
		width: 3,
		height: 2
	},
	{
		src: PUBLIC_URL + "gallery/bode/Bode-5.jpg",
		width: 3,
		height: 2
	},
	{
		src: PUBLIC_URL + "gallery/bode/Bode-6.jpg",
		width: 2,
		height: 3
	},
	
  ];