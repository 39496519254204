const PUBLIC_URL = "https://teskandiplebania.hu/";

export const thumb_photos = [
	{
		src: PUBLIC_URL + "gallery/teskand/thumbnail_200/Teskand-1.jpg",
		width: 3,
		height: 2
	},
	{
		src: PUBLIC_URL + "gallery/teskand/thumbnail_200/Teskand-2.jpg",
		width: 3,
		height: 2
	},
	{
		src: PUBLIC_URL + "gallery/teskand/thumbnail_200/Teskand-3.jpg",
		width: 3,
		height: 2
	},
	{
	  src: PUBLIC_URL + "gallery/teskand/thumbnail_200/Teskand-4.jpg",
	  width: 3,
	  height: 2
	},
	{
		src: PUBLIC_URL + "gallery/teskand/thumbnail_200/Teskand-5.jpg",
		width: 7,
		height: 5
	},
	{
		src: PUBLIC_URL + "gallery/teskand/thumbnail_200/Teskand-6.jpg",
		width: 3,
		height: 2
	},
	{
		src: PUBLIC_URL + "gallery/teskand/thumbnail_200/Teskand-7.jpg",
		width: 4,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/teskand/thumbnail_200/Teskand-8.jpg",
		width: 3,
		height: 2
	},
	{
		src: PUBLIC_URL + "gallery/teskand/thumbnail_200/Teskand-9.jpg",
		width: 3,
		height: 2
	},
	{
		src: PUBLIC_URL + "gallery/teskand/thumbnail_200/Teskand-10.jpg",
		width: 4,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/teskand/thumbnail_200/Teskand-11.jpg",
		width: 4,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/teskand/thumbnail_200/Teskand-12.jpg",
		width: 4,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/teskand/thumbnail_200/Teskand-13.jpg",
		width: 4,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/teskand/thumbnail_200/Teskand-14.jpg",
		width: 4,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/teskand/thumbnail_200/Teskand-15.jpg",
		width: 4,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/teskand/thumbnail_200/Teskand-16.jpg",
		width: 4,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/teskand/thumbnail_200/Teskand-17.jpg",
		width: 3,
		height: 4
	},
  ];