import React, { useState, useEffect } from "react";

import axios from "axios";
import { SpinnerCircular } from 'spinners-react';
import Error from "../components/error";

import Content from "../components/content";
import PageTitle from "../components/page-title";

import ReactPaginate from 'react-paginate';

const Home = () => {
	const PER_PAGE = 5;
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [news, setNews] = useState([]);
	const [pageCount, setPageCount] = useState(1);
	const [currentPage, setcurrentPage] = useState(0);
	const API = `https://templom.ravaszdi.hu/api/news?page=${currentPage+1}&per_page=${PER_PAGE}`;

	const handleFetch = () => {
		axios(API)
		.then((response) => {
			setNews(response.data.news.data);
			setPageCount(response.data.news.last_page);
		})
		.catch((error) => {
			console.error("Error fetching data: ", error);
			setError(error);
		})
		.finally(() => {
			setLoading(false);
		});
	}

	const handlePageChange = (selectedObject) => {
		setcurrentPage(selectedObject.selected);
	};

	useEffect(() => {
		setLoading(true);
		handleFetch();
	}, [currentPage])

	if (loading)
	{
		return(
			<>
				<Content>
					<PageTitle>Aktualitások</PageTitle>
					<div className="grid grid-cols-1 xl:grid-rows-1 mb-4 ">
						<div className="flex flex-row justify-center">
							<SpinnerCircular 
								color="#ffffbc"
							/>
						</div>
					</div>
				</Content>

				<div className="h-14 text-center bg-light-yellow fixed inset-x-0 bottom-0 py-1">
					<div className="flex flex-col items-center">
						<div className="">
							<ReactPaginate
								initialPage={0}
								pageCount={pageCount}
								pageRange={2}
								marginPagesDisplayed={1}
								onPageChange={handlePageChange}
								containerClassName={'flex h-12 font-medium rounded-full bg-yellow-50'}
								previousLabel={'<'}
								previousLinkClassName={'h-12 w-12 mr-1 flex justify-center items-center rounded-full bg-yellow-100 cursor-pointer'}
								nextLabel={'>'}
								nextLinkClassName={'h-12 w-12 ml-1 flex justify-center items-center rounded-full bg-yellow-100 cursor-pointer'}
								breakClassName={'h-12 w-12 ml-1 flex justify-center items-center rounded-full bg-yellow-50 cursor-pointer'}
								pageLinkClassName={'h-12 w-12 ml-1 flex justify-center items-center cursor-pointer leading-5 transition duration-150 ease-in'}
								disabledClassName={'disabled'}
								activeClassName={'rounded-full bg-CUSTOM_YELLOW_MED'}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}
	if (error)
	{
		return(
			<Content>
				<PageTitle>Aktualitások</PageTitle>
				<Error>Hiba történt</Error>
			</Content>
			
		);
	}

	return (
		<>
			<Content>
				<PageTitle>Aktualitások</PageTitle>
				{
					news.map((item, i) => {
						return (
							<div key={i}>
								<details className="w-11/12 m-auto border-solid border-2 border-dark-yellow rounded-lg p-3 mt-4 bg-yellow-50">
									<summary className="text-gray-800 text-3xl font-bold">
										{item.title}
										<div className="text-gray-700 font-normal text-2xl" dangerouslySetInnerHTML={{ __html: item.short_description }}></div>
									</summary>
									
									<div className="text-gray-700 text-2xl" dangerouslySetInnerHTML={{ __html: item.description }}></div>
									<div className="text-gray-700 text-2xl text-right">{item.last_update}</div>
								</details>
							</div>
						)
					})
				} 
			</Content>

			<div className="h-14 text-center bg-light-yellow fixed inset-x-0 bottom-0 py-1">
				<div className="flex flex-col items-center">
					<div className="">
						<ReactPaginate
							initialPage={0}
							pageCount={pageCount}
							pageRange={2}
							marginPagesDisplayed={1}
							onPageChange={handlePageChange}
							containerClassName={'flex h-12 font-medium rounded-full bg-yellow-50'}
							previousLabel={'<'}
							previousLinkClassName={'h-12 w-12 mr-1 flex justify-center items-center rounded-full bg-yellow-100 cursor-pointer'}
							nextLabel={'>'}
							nextLinkClassName={'h-12 w-12 ml-1 flex justify-center items-center rounded-full bg-yellow-100 cursor-pointer'}
							breakClassName={'h-12 w-12 ml-1 flex justify-center items-center rounded-full bg-yellow-50 cursor-pointer'}
							pageLinkClassName={'h-12 w-12 ml-1 flex justify-center items-center cursor-pointer leading-5 transition duration-150 ease-in'}
							disabledClassName={'disabled'}
							activeClassName={'rounded-full bg-CUSTOM_YELLOW_MED'}
						/>
					</div>
				</div>
			</div>
		</>

	);
}

export default Home;