import Content from "../components/content";
import RoundedBlock from "../components/rounded-block";
import PageTitle from "../components/page-title";
import Col11 from "../components/col11";

const AdvisoryBoard = () => {
	return (
		<Content>
			<PageTitle>Tanácsadó testület</PageTitle>

			<div className="grid grid-cols-1 m-auto w-11/12 xl:w-4/5">
				<div className="text-2xl">
					<div className="flex justify-center">
						<RoundedBlock>
							<Col11 name="Teskánd Szent Család Plébánia" value="8983 Babosdöbréte, Hegyi utca 2."></Col11>
							<Col11 name="Telefon" value="92/370-315"></Col11>
							<Col11 name="Mobil" value="30/548-8857"></Col11>
						</RoundedBlock>
					</div>
				</div>
			</div>

			<br />
			<div className="grid grid-cols-1 w-11/12 xl:w-3/5 m-auto">
				<div className="text-2xl">
					<div className="flex justify-center">
						<RoundedBlock>
							<div className="py-3 px-6 border-b border-dark-yellow grid grid-cols-2">
								<div className="col-span-2 text-center font-bold">
									Szalai Attila Plébános, elnök
								</div>
							</div>
							<Col11 name="Gombocz Károly (világi elnök)" value="Teskánd"></Col11>
							<Col11 name="Tóth Tamás (kántor)" value="Lickóvadamos"></Col11>
							<Col11 name="Büki Béla" value="Babosdöbréte"></Col11>
							<Col11 name="Büki Imre" value="Babosdöbréte"></Col11>
							<Col11 name="Bangó Szilárd" value="Babosdöbréte"></Col11>
							<Col11 name="Török László" value="Babosdöbréte"></Col11>
							<Col11 name="Keibl József" value="Babosdöbréte"></Col11>
							<Col11 name="Bogár Róbert" value="Böde"></Col11>
							<Col11 name="Takács Mária" value="Böde"></Col11>
							<Col11 name="Bolgár László" value="Böde"></Col11>
							<Col11 name="Tóth József" value="Boncodfölde"></Col11>
							<Col11 name="Sipos Ernő" value="Boncodfölde"></Col11>
							<Col11 name="Szemes Róbert" value="Boncodfölde"></Col11>
							<Col11 name="Rosta István" value="Hottó"></Col11>
							<Col11 name="Dervalics László" value="Teskánd"></Col11>
							<Col11 name="Badics Albert" value="Teskánd"></Col11>
							<Col11 name="Mátyás János" value="Teskánd"></Col11>
							<Col11 name="Czett Ferenc" value="Teskánd"></Col11>
						</RoundedBlock>
					</div>
				</div>
			</div>
		</Content>
	);
}
 
export default AdvisoryBoard;