import React, { useState, useEffect} from "react";

import axios from "axios";
import { SpinnerCircular } from 'spinners-react';
import Error from "../components/error";

import Content from "../components/content";
import PageTitle from "../components/page-title";
import PageSubtitle from "../components/page-subtitle";
import GridContainer from "../components/grid-container";
import RoundedBlock from "../components/rounded-block";
import Col212 from "../components/col212";
import Text2xl from "../components/text2xl";


const Schedule = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [post, getPost] = useState([])
	const [last_update, getLastUpdate] = useState([])

	const API = 'https://templom.ravaszdi.hu/api/timetables';

	const fetchPost = () => {
		axios(API)
		.then((response) => {
			getPost(response.data.timetables);
			getLastUpdate(response.data.last_update)
		})
		.catch((error) => {
			console.error("Error fetching data: ", error);
			setError(error);
		})
		.finally(() => {
			setLoading(false);
		});
	}

	useEffect(() => {
		fetchPost()
	}, [])

	if (loading)
	{
		return(
			<Content>
				<PageTitle>Miserend</PageTitle>
				<div className="grid grid-cols-1 xl:grid-rows-1 mb-4 ">
					<div className="flex flex-row justify-center">
						<SpinnerCircular 
							color="#ffffbc"
						/>
					</div>
				</div>
			</Content>
		);
	}
	if (error)
	{
		return(
			<Content>
				<PageTitle>Miserend</PageTitle>
				<Error>Hiba történt</Error>
			</Content>
			
		);
	}

	return (
		<Content>
			<PageTitle>Miserend</PageTitle>
			<PageSubtitle>Utolsó módosítás: {last_update}</PageSubtitle>

			<GridContainer>
				{post.map((item, i) => {
					return (
						<Text2xl key={i}>
							<h2 className="text-4xl text-center">{ item.title }</h2>
							<div className="flex justify-center">
								<RoundedBlock>
									<Col212 city="Boncodfölde" time={ item.boncodfolde_time } desc={ item.boncodfolde_desc }></Col212>
									<Col212 city="Teskánd" time={ item.teskand_time } desc={ item.teskand_desc }></Col212>
									<Col212 city="Babosdöbréte" time={ item.babosdobrete_time } desc={ item.babosdobrete_desc }></Col212>
									<Col212 city="Hottó" time={ item.hotto_time } desc={ item.hotto_desc }></Col212>
									<Col212 city="Böde" time={ item.bode_time } desc={ item.bode_desc }></Col212>
								</RoundedBlock>
							</div>
						</Text2xl>

					)
				})}
			</GridContainer>
		</Content>

	);
}
 
export default Schedule;