import PreviousMap from "postcss/lib/previous-map";

const PageTitle = (props) => {
	return (
		<>
			<h2 className="text-4xl font-bold text-center pt-4 text-gray-800 gabriola">{props.children}</h2>
			<br />
		</>

	);
}
 
export default PageTitle;