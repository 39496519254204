import MenuButton from './menu-button';
import MenuLinkButton from './menu-link-button';
import MenuButtonDropdown from './menu-button-dropdown';

const Navbar = () => {
	return (
		<nav className="navbar">
			<div className="flex justify-around place-items-stretch bg-CUSTOM_BROWN py-1">
				<MenuButton to="/">Aktualitások</MenuButton>
				<MenuButtonDropdown name="Templomaink">
					<div><MenuButton to="/teskand">Teskánd</MenuButton></div>
					<div><MenuButton to="/babosdobrete">Babosdöbréte</MenuButton></div>
					<div><MenuButton to="/boncodfolde">Boncodfölde</MenuButton></div>
					<div><MenuButton to="/bode">Böde</MenuButton></div>
					<div><MenuButton to="/hotto">Hottó</MenuButton></div>
					{/* <div><MenuButton to="#">Boncodfölde</MenuButton></div> */}
				</MenuButtonDropdown>
				<MenuButtonDropdown name="Lelkiség">
					<div><MenuButton to="/napi_evangelium">Napi evangélium</MenuButton></div>
					<div><MenuButton to="/ima">Ima</MenuButton></div>
					{/* <div><MenuButton to="#">Írások</MenuButton></div>
					<div><MenuButton to="#">Liturgia</MenuButton></div>
					<div><MenuButton to="#">Szalai Attila gondolatai</MenuButton></div> */}
				</MenuButtonDropdown>
				<MenuButtonDropdown name="Kapcsolódó honlapok">
					<div><MenuLinkButton href="https://www.martinus.hu/">Martinus</MenuLinkButton></div>
					<div><MenuLinkButton href="https://www.facebook.com/Tesk%C3%A1ndi-Pl%C3%A9b%C3%A1nia-108609876327291">Facebook oldal</MenuLinkButton></div>
				</MenuButtonDropdown>
				<MenuButtonDropdown name="Közösség">
					<div><MenuButton to="/plebanosunk">Plébánosunk</MenuButton></div>
					<div><MenuButton to="/rozsafuzer">Rózsafüzér társulat</MenuButton></div>
					<div><MenuButton to="/testulet">Tanácsadó testület</MenuButton></div>
				</MenuButtonDropdown>
				<MenuButtonDropdown name="Információ">
					<div><MenuButton to="/miserend">Miserend</MenuButton></div>
					<div><MenuButton to="/hozzajarulas">Egyházközösségi hozzájárulás</MenuButton></div>
					{/* <div><MenuButton to="#">Projektek</MenuButton></div> */}
				</MenuButtonDropdown>
			</div>
		</nav>
	);
}
 
export default Navbar;