const Details = (props) => {
	return (
		<details className="w-11/12 m-auto border-solid border-2 border-dark-yellow rounded-lg p-3 mt-4 bg-yellow-50">
			<summary className="text-left text-gray-800 text-3xl font-bold">
				{props.title}
			</summary>

			<div className="text-gray-700 text-2xl">
				{props.children}
			</div>
		</details>
	);
}
 
export default Details;