import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "./bode_images";
import { thumb_photos } from "./bode_images_thumb200";

import PageTitle from "../../components/page-title";
import Content from "../../components/content";
import ContentBlock from "../../components/content-block";
import Text2xl from "../../components/text2xl";

const Bode = () => {
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);
	
	const openLightbox = useCallback((event, { photo, index }) => {
	  setCurrentImage(index);
	  setViewerIsOpen(true);
	}, []);
	
	const closeLightbox = () => {
	  setCurrentImage(0);
	  setViewerIsOpen(false);
	};

	const customStyles = {
		view: () => ({
			// none of react-images styles are passed to <View />
			position: 'relative',
			'& > img': {
				position: 'relative',
				margin: '0 auto'
			},
		})
	};

	return (
		<Content>
			<PageTitle>Böde</PageTitle>
			<ContentBlock>
				<Text2xl>
				<p>
					<strong>Böde-Zalaszentmihályfai Római Katolikus Szent Mihály Templom</strong><br />
					Böde község Zala megye északi részén Zalaegerszegtől 13 km-re nyugatra fekszik. Első írásos említése 1407-ben: Bede. 
					A település neve személynévből keletkezett magyar névadással. Alapjául szolgáló személynév a Benedek becéző alakja: Bede ill. Böde.
					Árpád kori római katolikus temploma a Bödébe vezető út mentén Böde és Zalaszentmihályfa faluk között jobbra egy dombon emelkedik. 
					A román stílusú, Szent Mihály főangyal tiszteletére szentelt templom a XIII. század elején 1220 körül épült. A török időkben elpusztult
					 épületet később gótikus stílusban átalakították, majd elpusztult szentélye helyén a XVIII. században 1750-ben újat emeltek és megnagyobbították,
					  barokk stílusban. Fennmaradt írásos oklevél csak 1424-ben említi. A következő nagyobb renoválásra 1923-ban került sor. Az 1966-ban végzett
					   műemléki kutatás után helyreállítása, 1970-71-ben történt meg. Restaurálták, s a középkori elemeket újra láthatóvá tették.
				</p>
				<p>
					<strong>A templom legendája</strong><br />
					A kos- és a tehénfej a néphit szerint nem véletlenül került a templom tornyának ablakába. Az ország török megszállásának valamely esztendejében, 
					amikor a török csapatok Bécs irányába vonultak, a romos templom tövében legeltette egy pásztor a nyáját. A katonáktól félve elbújt az állatok közé, 
					és megfogadta, ha ép bőrrel megússza a találkozást, akkor helyreállítja a templomot. A juhnyáj és a pásztor emlékét őrzi a kosfej.
					<br />
					Egy másik hasonló monda is él a nép körében, miszerint még előbb a tartárjárás idején egy henteslegény rejtőzött el a dombok között az ellenséges
					 katonák elől. S később hálája jeléül építette a templomot. A kos- és a tehénfej pedig foglalkozásának jelképei.
				</p>
				<p>
					<strong>Régészeti kutatások eredménye</strong><br />
					E történetnél azonban hitelesebb adatokkal is szolgál az 1220 körül épített kis templom művészettörténeti kutatása, melyet az 1960-as években végeztek el 
					a régészek. Nem tudjuk pontosan, ki építette a templomot, ki volt a kegyura. A szomszédos két település közül Böde 1407 óta, Szentmihályfa pedig 1410 óta 
					szerepel az okiratokban, azonban ezen települések a régészeti adatok bizonysága szerint már a XI. századtól léteztek.
					A templomhajó déli falán lévő három félköríves, tölcsérbélletű ablak és a nyugati torony kettős ikerablakai tisztán megőrizték eredeti formájukat, 
					a templom más részletei magukon viselik a későbbi átalakítások nyomát. Az épület legizgalmasabb része a nyugati oldalon emelkedő zömök, négyzetes 
					alapterületű harangtorony, amelynek kétharmad része a hajó belsejében áll, egyharmada a homlokzat elé ugrik, félköríves boltozatával előcsarnokot képezve 
					a nyugatról nyíló bejáratnak.
					A torony kettős ikerablakának Bödére néző oszlopfőin találjuk a műemlék építéstörténetileg érdekes és a népi legendák alapjául szolgáló részletét, 
					a Biblia áldozati állatait megszemélyesítő kos- és tehénfejet. 
					Nyugati kapuját kőfaragás díszíti. A kapubejárat bélletének díszítése sajátos románkori értékek hordozója. A faragó mester fantáziáját dicséri, 
					hogy a díszítés nem egyforma, mindkét oldalon más a csavarmenetes faragás, s az oszlopok felső végét díszítő növényi indákból font ornamentika 
					ugyancsak különbözik a két oldalon. Az is ritkaságnak számít, hogy az oszlop csavarmenetének díszítése a félmagasságnál motívumot vált.
					A kapu fonatos timpanondísze a honfoglalás kori szíjvégek mintázatát illeszti a félköríves ajtókeretre. A timpanon belsején lévő faragott 
					kövön Isten báránya látható a kereszttel.
					A torony belső sarkai két oszlopon nyugszanak, egyúttal urasági karzat alappillérjeiként szolgálva. A belsejében lévő keleti toronyfal az alsó szinten 
					három félköríves nyílással (közepén egy nagy, kétoldalt két keskeny) kapcsolódik a templomtér belsejéhez, könnyed, áttört toronyaljat képezve. 
					E toronyalja fölötti részt az alsó nyílásritmusnak megfelelően ismét nyílások tagolják, így a torony első szintje szabályos karzattá válik. 
					Ez a karzat egészen a torony nyugatin faláig tart, teljes mélységében.
					Az 1755-ös Canonica Visitatio okirat fontos adatokat tartalmaz a templom története szempontjából. Miszerint a templomot régi romokon újjáépítették 
					és Szent Mihály arkangyal tiszteletére szentelték. Erről tanúskodik a diadalív felett lévő középkori felirat is.
				</p>
				<p>
					<strong>A templom bejelentkezéssel látogatható!</strong>
				</p>
				<p>	
					<strong>Irodalomjegyzék:</strong>
					Valter Ilona: A Zalaszentmihályfai Római Katolikus Templom kutatása <br />
					Magyar Műemlékvédelem 1971-72 (145-160.o.) <br />
					Akadémia Kiadó Budapest 1974 <br /><br />
					
					H. Vladár Ágnes: A Zalaszentmihályfai Római Katolikus Templom helyreállítása<br />
					Magyar Műemlékvédelem 1971-72 (161-178.o.)<br />
					Akadémia Kiadó Budapest 1974<br /><br />
					
					Magyar Hajnalka: Műemlék templomok Zalában, A kos Bödére néz<br />
					Zalai Magazin, 1990. jan. 24. (16-17.o.)<br /><br />
					
					Baksa Brigitta, Balogh Balázs, Gulácsi Zsuzsanna, Hála József, Horváth Gyula, Lackner Mónika, Sándor Ildikó: Néprajzi dolgozatok Bödéről<br />
					Szabadtéri Néprajzi Múzeum Szentendre 1990<br /><br />
					
					Császár János: Történeti épületszerkezetek a Böde, Zalaszentmihályfai római katolikus templom épületében<br />
					Építő szakmérnöki tanulmány 2002
				</p>
				</Text2xl>
			</ContentBlock>
			<br />
			<ContentBlock>
				<Gallery photos={thumb_photos} onClick={openLightbox} />
				<ModalGateway>
					{viewerIsOpen ? (
					<Modal onClose={closeLightbox}>
						<Carousel
						currentIndex={currentImage}
						styles={customStyles}
						views={photos.map(x => ({
							...x,
							srcset: x.srcSet,
							caption: x.title
						}))}
						/>
					</Modal>
					) : null}
				</ModalGateway>
			</ContentBlock>
		</Content>
	);
}
 
export default Bode;