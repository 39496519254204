import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "./boncodfolde_images";
import { thumb_photos } from "./boncodfolde_images_thumb200";

import PageTitle from "../../components/page-title";
import Content from "../../components/content";
import ContentBlock from "../../components/content-block";
import Text2xl from "../../components/text2xl";

const Hotto = () => {
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);
	
	const openLightbox = useCallback((event, { photo, index }) => {
	  setCurrentImage(index);
	  setViewerIsOpen(true);
	}, []);
	
	const closeLightbox = () => {
	  setCurrentImage(0);
	  setViewerIsOpen(false);
	};

	const customStyles = {
		view: () => ({
			// none of react-images styles are passed to <View />
			position: 'relative',
			'& > img': {
				position: 'relative',
				margin: '0 auto'
			},
		})
	};

	return (
		<Content>
			<PageTitle>Boncodfölde</PageTitle>
			{/* <ContentBlock>
				<Text2xl>
				<p>
					leírás..
				</p>
				</Text2xl>
			</ContentBlock> */}
			<ContentBlock>
				<Gallery photos={thumb_photos} onClick={openLightbox} />
				<ModalGateway>
					{viewerIsOpen ? (
					<Modal onClose={closeLightbox}>
						<Carousel
						currentIndex={currentImage}
						styles={customStyles}
						views={photos.map(x => ({
							...x,
							srcset: x.srcSet,
							caption: x.title
						}))}
						/>
					</Modal>
					) : null}
				</ModalGateway>
			</ContentBlock>
		</Content>
	);
}
 
export default Hotto;