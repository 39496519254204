import WebsiteName from "./website-name";
import { Link } from 'react-router-dom';
import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import '../fonts/gabriola/font.css';

const MobileMenuButton = ({ children, to, menuOpen, setMenuOpen }) => (
	<Link  className="m-0 p-0" to={to} onClick={() => setMenuOpen(!menuOpen)}>
		<div className="nav-btn
			bg-gradient-to-b from-CUSTOM_YELLOW_BRIGHT via-CUSTOM_YELLOW_MED to-CUSTOM_YELLOW_DARK text-2xl text-center gabriola">
			{children}
		</div>
	</Link>
);

const MobileMenuLinkButton = ({ children, href, menuOpen, setMenuOpen }) => (
	<a  className="m-0 p-0" href={href} target="_blank" rel="noopener noreferrer" onClick={() => setMenuOpen(!menuOpen)}>
		<div className="nav-btn
			bg-gradient-to-b from-CUSTOM_YELLOW_BRIGHT via-CUSTOM_YELLOW_MED to-CUSTOM_YELLOW_DARK text-2xl text-center gabriola">
			{children}
		</div>
	</a>
);

class MobileNavbar extends React.Component {
	constructor (props) {
		super(props)
		this.state = {
		  menuOpen: false
		}
	  }
	
	  // This keeps your state in sync with the opening/closing of the menu
	  // via the default means, e.g. clicking the X, pressing the ESC key etc.
	  handleStateChange (state) {
		this.setState({menuOpen: state.isOpen})  
	  }
	  
	  // This can be used to close the menu, e.g. when a user clicks a menu item
	  closeMenu () {
		this.setState({menuOpen: false})
	  }
	
	  // This can be used to toggle the menu, e.g. when using a custom icon
	  // Tip: You probably want to hide either/both default icons if using a custom icon
	  // See https://github.com/negomi/react-burger-menu#custom-icons
	  toggleMenu () {
		this.setState(state => ({menuOpen: !state.menuOpen}))
	  }

	  showSettings (event) {
		event.preventDefault();
	  }

	render () {
		return (
			<div>
				<div className="p-3 fixed top-0 left-0 right-0 bg-yellow-50">
					<WebsiteName></WebsiteName>
				</div>
				<Menu isOpen={this.state.menuOpen} onStateChange={(state) => this.handleStateChange(state)} width={'100%'} right>
					<MobileMenuButton to="/" >Aktualitások</MobileMenuButton>
					<div className="gabriola text-2xl">Templomaink</div>
					<MobileMenuButton to="/teskand" >Teskánd</MobileMenuButton>
					<MobileMenuButton to="/babosdobrete" >Babosdöbréte</MobileMenuButton>
					<MobileMenuButton to="/boncodfolde" >Boncodfölde</MobileMenuButton>
					<MobileMenuButton to="/bode" >Böde</MobileMenuButton>
					<MobileMenuButton to="/hotto" >Hottó</MobileMenuButton>
					{/* <MobileMenuButton to="#" >Boncodfölde</MobileMenuButton> */}
					<div className="gabriola text-2xl">Lelkiség</div>
					<MobileMenuButton to="/napi_evangelium" >Napi evangélium</MobileMenuButton>
					<MobileMenuButton to="/ima" >Ima</MobileMenuButton>
					{/* <MobileMenuButton to="#" >Írások</MobileMenuButton>
					<MobileMenuButton to="#" >Liturgia</MobileMenuButton>
					<MobileMenuButton to="#" >Szalai Attila gondolatai</MobileMenuButton> */}
					<div className="gabriola text-2xl">Kapcsolódó honlapok</div>
					<MobileMenuLinkButton href="https://www.martinus.hu/" >Martinus</MobileMenuLinkButton>
					<MobileMenuLinkButton href="https://www.facebook.com/Tesk%C3%A1ndi-Pl%C3%A9b%C3%A1nia-108609876327291" >Facebook oldal</MobileMenuLinkButton>
					<div className="gabriola text-2xl">Közösség</div>
					<MobileMenuButton to="/plebanosunk" >Plébánosunk</MobileMenuButton>
					<MobileMenuButton to="/rozsafuzer" >Rózsafüzér társulat</MobileMenuButton>
					<MobileMenuButton to="/testulet" >Tanácsadó testület</MobileMenuButton>
					<div className="gabriola text-2xl">Információ</div>
					<MobileMenuButton to="/miserend" >Miserend</MobileMenuButton>
					<MobileMenuButton to="/hozzajarulas" >Egyházközösségi hozzájárulás</MobileMenuButton>
					{/* <MobileMenuButton to="#" >Projektek</MobileMenuButton> */}
					<br />
					<br />
					<br />
				</Menu>
			</div>
		);
	}
}

export default MobileNavbar;