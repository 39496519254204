import Header from './components/header';
import Infobar from './components/infobar';
import Navbar from './components/navbar';
import MobileNavbar from './components/mobile-navbar';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Schedule from './pages/schedule';
import Priest from './pages/priest';
import Faith from './pages/faith';
import Contribution from './pages/contribution';
import Rosary from './pages/rosary';
import AdvisoryBoard from './pages/advisory_board';
import NoPage from './pages/404';
import DailyGospel from './pages/daily_gospel';

import { useMediaQuery } from 'react-responsive'
import Teskand from './pages/churches/teskand';
import Bode from './pages/churches/bode';
import Hotto from './pages/churches/hotto';
import Babosdobrete from './pages/churches/babosdobrete';
import Boncodfolde from './pages/churches/boncodfolde';

function App() {
	const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })
	//const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1280px)' })
	//const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
	//const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

	return (
		<Router>
			<div className="App">
				{isDesktopOrLaptop && 
					<div className="content-center">
						<Header></Header>
						<Infobar></Infobar>
						<Navbar></Navbar>
					</div>
				}
				{isTabletOrMobile &&
					<div className="content-center mb-14">
						<MobileNavbar pageWrapId={"content"} outerContainerId={"App"}></MobileNavbar>
					</div>
				}
				<div className="content">
					<Routes path="/">
						<Route index element={<Home></Home>}></Route>
						<Route path="miserend" element={<Schedule></Schedule>}></Route>
						<Route path="plebanosunk" element={<Priest></Priest>}></Route>
						<Route path="napi_evangelium" element={<DailyGospel></DailyGospel>}></Route>
						<Route path="ima" element={<Faith></Faith>}></Route>
						<Route path="hozzajarulas" element={<Contribution></Contribution>}></Route>
						<Route path="rozsafuzer" element={<Rosary></Rosary>}></Route>
						<Route path="testulet" element={<AdvisoryBoard></AdvisoryBoard>}></Route>
						<Route path="teskand" element={<Teskand></Teskand>}></Route>
						<Route path="bode" element={<Bode></Bode>}></Route>
						<Route path="hotto" element={<Hotto></Hotto>}></Route>
						<Route path="babosdobrete" element={<Babosdobrete></Babosdobrete>}></Route>
						<Route path="boncodfolde" element={<Boncodfolde></Boncodfolde>}></Route>
						<Route path="*" element={<NoPage></NoPage>}></Route>
					</Routes>
					<div>
						{/* <p>Képernyő teszt:</p>
						{isDesktopOrLaptop && <p>You are a desktop or laptop</p>}
						{isBigScreen && <p>You  have a huge screen</p>}
						{isTabletOrMobile && <p>You are a tablet or mobile phone</p>}
						<p>Your are in {isPortrait ? 'portrait' : 'landscape'} orientation</p> */}
					</div>
				</div>
			</div>
		</Router>
	);
}

export default App;
