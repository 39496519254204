const PUBLIC_URL = "https://teskandiplebania.hu/";

export const photos = [
	{
		src: PUBLIC_URL + "gallery/hotto/Hotto-1.jpg",
		width: 3,
		height: 2
	},
	{
		src: PUBLIC_URL + "gallery/hotto/Hotto-2.jpg",
		width: 3,
		height: 2
	},
	{
		src: PUBLIC_URL + "gallery/hotto/Hotto-3.jpg",
		width: 3,
		height: 2
	},
  ];