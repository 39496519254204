const PageSubtitle = (props) => {
	return (
		<>
			<div className="text-2xl text-center text-gray-800">{props.children}</div>
			<br />
		</>
		
	);
}
 
export default PageSubtitle;