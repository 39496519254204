import Logo from "./logo";
import WebsiteName from "./website-name";
import PhotoSlider from "./photo-slider";

const Header = () => {
	return (
		<div className="grid grid-cols-8 gap-5 content-center place-items-center bg-yellow-50">
			<Logo></Logo>
			<WebsiteName></WebsiteName>
			<div className="col-span-5">
				<PhotoSlider></PhotoSlider>
			</div>
		</div>
	);
}
 
export default Header;