import React, { useState, useEffect} from "react";

import axios from "axios";
import { SpinnerCircular } from 'spinners-react';
import Error from "../components/error";

import Content from "../components/content";
import PageTitle from "../components/page-title";
import PageSubtitle from "../components/page-subtitle";
import GridContainer from "../components/grid-container";
import RoundedBlock from "../components/rounded-block";
import Col11 from "../components/col11";
import Text2xl from "../components/text2xl";

const Rosary = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [post, getPost] = useState([])
	const [last_update, getLastUpdate] = useState([])
	const API = 'https://templom.ravaszdi.hu/api/rosaries';

	const fetchPost = () => {
		axios(API)
		.then((response) => {
			response.data.rosaries.map((item, i) =>
			{		
				item.calculated_name_1 = item.type + " " +  (((item.start - 1) % 5) + 1) + ".";
				item.calculated_name_2 = item.type + " " + (((item.start) % 5) + 1) + ".";
				item.calculated_name_3 = item.type + " " + (((item.start + 1) % 5) + 1) + ".";
				item.calculated_name_4 = item.type + " " + (((item.start + 2) % 5) + 1) + ".";
				item.calculated_name_5 = item.type + " " + (((item.start + 3) % 5) + 1) + ".";
			})

			getPost(response.data.rosaries)
			getLastUpdate(response.data.last_update)
		})
		.catch((error) => {
			console.error("Error fetching data: ", error);
			setError(error);
		})
		.finally(() => {
			setLoading(false);
		});
	}

	useEffect(() => {
		fetchPost()
	}, [])

	if (loading)
	{
		return(
			<Content>
				<PageTitle>Napi evangélium</PageTitle>
				<div className="grid grid-cols-1 xl:grid-rows-1 mb-4 ">
					<div className="flex flex-row justify-center">
						<SpinnerCircular 
							color="#ffffbc"
						/>
					</div>
				</div>
			</Content>
		);
	}
	if (error)
	{
		return(
			<Content>
				<PageTitle>Napi evangélium</PageTitle>
				<Error>Hiba történt</Error>
			</Content>
			
		);
	}

	return (
		<Content>
			<PageTitle>Rózsafüzér társulat</PageTitle>
			<PageSubtitle>Utolsó módosítás: {last_update}</PageSubtitle>

			<GridContainer extraClass="xl:grid-cols-2">
				{post.map((item, i) => {
					return (
						<Text2xl key={i}>
							<div className="flex justify-center">
								<RoundedBlock>
									<Col11 name={item.calculated_name_1} value={ item.name_1 }></Col11>
									<Col11 name={item.calculated_name_2} value={ item.name_2 }></Col11>
									<Col11 name={item.calculated_name_3} value={ item.name_3 }></Col11>
									<Col11 name={item.calculated_name_4} value={ item.name_4 }></Col11>
									<Col11 name={item.calculated_name_5} value={ item.name_5 }></Col11>
								</RoundedBlock>
							</div>
						</Text2xl>
					)
				})}
				<Text2xl>
					<div className="flex justify-center">
						<RoundedBlock>
							<div className="grid grid-cols-1">
								<div className="py-3 px-6 border-b border-dark-yellow">
									<span className="font-bold">Az örvendetes olvasó titkai:</span><br />
									1. Akit Te, Szent Szűz a Szentlélektől fogantál, <br />
									2. Akit Te, Szent Szűz, Erzsébetet látogatván hordoztál, <br />
									3. Akit Te, Szent Szűz a világra szültél, <br />
									4. Akit Te, Szent Szűz a templomban bemutattál, <br />
									5. Akit Te, Szent Szűz a templomban megtaláltál, <br />
								</div>
								<div className="py-3 px-6 border-b border-dark-yellow">
									<span className="font-bold">A világosság olvasója titkai:</span><br />
									1. Aki a Jordán vizében megkeresztelkedett,<br />
									2. Aki a kánai menyegzőn kinyilvánította isteni erejét,<br />
									3. Aki meghirdette Isten országát,<br />
									4. Aki a Tábor hegyén megmutatta isteni dicsőségét,<br />
									5. Aki az Eucharisztiában nekünk adta önmagát,<br />
								</div>
								<div className="py-3 px-6 border-b border-dark-yellow">
									<span className="font-bold">A fájdalmas olvasó titkai:</span><br />
									1. Aki érettünk vérrel verítékezett,<br />
									2. Akit érettünk megostoroztak,<br />
									3. Akit érettünk tövissel koronáztak,<br />
									4. Aki érettünk a keresztet hordozta,<br />
									5. Akit érettünk keresztre feszítettek,<br />
								</div>
								<div className="py-3 px-6 border-b border-dark-yellow">
									<span className="font-bold">A dicsőséges olvasó titkai:</span><br />
									1. Aki a halálból feltámadt,<br />
									2. Aki a mennybe felment,<br />
									3. Aki nekünk a Szentlelket elküldte;,<br />
									4. Aki Téged, Szent Szűz, a mennybe fölvett,<br />
									5. Aki Téged, Szent Szűz, a mennyben megkoronázott.<br />
								</div>
							</div>
						</RoundedBlock>
					</div>
				</Text2xl>
			</GridContainer>
		</Content>

	);
}
 
export default Rosary;