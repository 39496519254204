import error from '../images/error_icon.png';

const Error = (props) => {
	return (
		<div className=" w-11/12 m-auto border-solid border-2 border-dark-yellow bg-yellow-50 rounded-lg p-3 mt-4">
			<div className="grid grid-cols-2">
				<div><img src={error} alt="Error icon" /></div>
				<div className="text-4xl self-center">
					{props.children}
				</div>

			</div>
		</div>
	);
}
 
export default Error;