import React, { useState} from "react";

import Content from "../components/content";
import PageTitle from "../components/page-title";

const Contribution = () => {
	const [input, setInput] = useState("")
	const handleChange = event =>  setInput(event.target.value)

	const items = []
	for (let i = 0; i < 48; i=i+2) {
		items.push([{i},
					40000+i*10000,
					(40000+i*10000)*0.005,
					(40000+i*10000)*0.06]);
	}

	return (
		<Content>
			<PageTitle>Egyházközösségi hozzájárulás</PageTitle>

			<div className="w-11/12 m-auto border-solid border-2 border-dark-yellow bg-yellow-50 rounded-lg p-3 mb-4">
				<div className="justify-items-center text-2xl">
					<p>Számlaszám: <b>10700110-67634165-52000001</b> </p>
					<p>Az egyházközségi hozzájárulás (egyházi adó) 0,5%-os fizetésének táblázata minden önálló keresetteé vagy nyugdíjjal rendelkező személy <u>nettó</u> fizetése, illetve nyugdíja után</p>
				</div>
				<div className="grid grid-cols-3 justify-items-center gap-4 text-2xl">
					<div className="font-bold">
						Havi nettó kereset / nyugdíj
					</div>
					<div className="font-bold">
						0,5%
					</div>
					<div className="font-bold">
						Éves összeg (Ft) (0,5% x 12)
					</div>
					<div>
						<input
							type="number"
							pattern="[0-9]*"
							onChange={handleChange}
							id="name"
							className="
								form-control
								block
								w-full
								px-4
								py-2
								text-2xl
								font-normal
								text-gray-700
								bg-white bg-clip-padding
								border border-solid border-gray-300
								rounded
								transition
								ease-in-out
								m-0
								focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
						/>
					</div>
					<div>
						{input ? `${parseInt(input*0.005)}` : ''}
					</div>
					<div>
						{input ? `${parseInt(input*0.06)}` : ''}
					</div>
					{items.map((item) => {
						return (
							<>
								<div>
									{item[1]}
								</div>
								<div>
									{item[2]}
								</div>
								<div>
									{item[3]}
								</div>
							</>
						)
					})}
				</div>
			</div>
		</Content>
	)
}
 
export default Contribution;