import React, { useState, useEffect} from "react";

import axios from "axios";
import { SpinnerCircular } from 'spinners-react';
import Error from "../components/error";

import ReactAudioPlayer from 'react-audio-player';

import Content from "../components/content";
import PageTitle from "../components/page-title";

const DailyGospel = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [content, getContent] = useState([])
	const API = 'https://templom.ravaszdi.hu/api/daily_gospel';

	const fetchPost = () => {
		axios(API)
		.then((response) => {
			getContent(response.data);
		})
		.catch((error) => {
			console.error("Error fetching data: ", error);
			setError(error);
		})
		.finally(() => {
			setLoading(false);
		});
	}

	useEffect(() => {
		fetchPost();
		
	}, []);

	if (loading)
	{
		return(
			<Content>
				<PageTitle>Napi evangélium</PageTitle>
				<div className="grid grid-cols-1 xl:grid-rows-1 mb-4 ">
					<div className="flex flex-row justify-center">
						<SpinnerCircular 
							color="#ffffbc"
						/>
					</div>
				</div>
			</Content>
		);
	}
	if (error)
	{
		return(
			<Content>
				<PageTitle>Napi evangélium</PageTitle>
				<Error>Hiba történt</Error>
			</Content>
			
		);
	}

	return (
		<Content>
			<PageTitle>Napi evangélium</PageTitle>
			<div className="grid grid-cols-1 xl:grid-rows-1 mb-4">
				<div className=" w-11/12 m-auto border-solid border-2 border-dark-yellow bg-yellow-50 rounded-lg p-3 mt-4">
					<div className="text-gray-700 text-2xl">
						<h2 className="text-4xl text-center">Lejátszás:</h2>
						<div className="p-4 place-self-center">
							<ReactAudioPlayer
								src={ content.player }
								style={{width: '100%'}}
								controls
							/>
						</div>

					</div>
				</div>
				<div className="w-11/12 m-auto border-solid border-2 border-dark-yellow bg-yellow-50 rounded-lg p-3 mt-4">
					<div className="text-gray-700 text-2xl">
						<h2 className="text-4xl text-center">{ content.evangelium_title } </h2>
						{ content.evangelium_content } <br />
						<span className="font-bold">{ content.evangelium_author }</span>
					</div>
				</div>
				<div className="w-11/12 xl:row-span-3 m-auto border-solid border-2 border-dark-yellow bg-yellow-50 rounded-lg p-3 mt-4">
					<div className="text-gray-700 text-2xl">
						<h2 className="text-4xl text-center">{ content.elmelkedes_title }</h2>
						{ content.elmelkedes_content }
					</div>
				</div>
				<div className=" w-11/12 m-auto border-solid border-2 border-dark-yellow bg-yellow-50 rounded-lg p-3 mt-4">
					<div className="text-gray-700 text-2xl">
						{ content.imadsag_content }
					</div>
				</div>
				<div className=" w-11/12 m-auto p-3 mt-4">
					<div className="text-blue-700 text-2xl">
						<a href="https://evangelium.katolikus.hu/" target="_blank" rel="noopener noreferrer">Napi evangélium forrása</a>
					</div>
				</div>
			</div>
		</Content>
	);
}
 
export default DailyGospel;