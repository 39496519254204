import Content from '../components/content';
import Details from '../components/details';
import PageTitle from '../components/page-title';

const Faith = () => {
	return (
		<Content>
			<PageTitle>Ima</PageTitle>
			<div className="grid grid-cols-1 xl:grid-cols-2 text-center">
				<Details title="Apostoli hitvallás (Hiszekegy)">
					<span className="italic">
						Hitünk legfontosabb igazságainak összefoglalását Apostoli Hitvallásnak nevezzük, 
						mert elemeiben az apostolokra megy vissza. Ez a hit ősi szabálya. 
						Eleinte a keresztelésnél, később más szertartásokban is mondták. 
						De nem csak hitvallás, hanem imádság is: Isten titkainak imádó szemlélése, 
						hálaadás a kinyilatkoztatott igazságokért és Istennek szóló hűség-nyilatkozat. 
						Nem csak az Istenhez szóló beszéd lehet imádság, hanem az Istenről szóló is. 
						- Szertartásokon kívül is rendszeresen éljünk vele hitünk erősítésére.
					</span>

					<div className="border-b border-dark-yellow"></div>

					HISZEK EGY Istenben,<br />
					mindenható Atyában,<br />
					mennynek és földnek Teremtőjében.<br />
					És Jézus Krisztusban,<br />
					az Ő egyszülött Fiában, a mi Urunkban,<br />
					aki fogantatott Szentlélektől,<br />
					született Szűz Máriától,<br />
					szenvedett Poncius Pilátus alatt,<br />
					megfeszítették, meghalt és eltemették.<br />
					Alászállt a poklokra,<br />
					harmadnapon feltámadt a halottak közül,<br />
					felment a mennybe,<br />
					ott ül a mindenható Atya Isten jobbján,<br />
					onnan jön el ítélni élőket és holtakat.<br />
					Hiszek a Szentlélekben.<br />
					Hiszem a katolikus Anyaszentegyházat,<br />
					a szentek közössségét, a bűnök bocsánatát,<br />
					a test feltámadását és az örök életet.<br />
					Amen. <br />
				</Details>
				<Details title="Az Úr imádsága (Miatyánk)">
					Mi Atyánk, aki a mennyekben vagy,<br />
					szenteltessék meg a te neved;<br />
					jöjjön el a te országod;<br />
					legyen meg a te akaratod,<br />
					amint a mennyben, úgy a földön is.<br />
					Mindennapi kenyerünket add meg nekünk ma;<br />
					és bocsásd meg vétkeinket,<br />
					miképpen mi is megbocsátunk<br />
					az ellenünk vétkezőknek;<br />
					és ne vígy minket kísértésbe,<br />
					de szabadíts meg a gonosztól! Ámen.<br />
				</Details>
				<Details title="Az angyali üdvözlet (Üdvözlégy)">
					<span className="italic">
						A Boldogságos Szüzet legméltóbban a Szent Lukács evangéliumából vett szavakkal köszönthetjük.
						Az első mondat az angyaltól tanult üdvözlés, a második Erzsébeté (Lk 1, 28. 42).
						Az imádság második fele az Egyház által hozzáfűzött esedezés.
					</span>
				
					<div className="border-b border-dark-yellow"></div>

					Üdvözlégy Mária, kegyelemmel teljes, az Úr van teveled,<br />
					áldott vagy te az asszonyok között,<br />
					és áldott a te méhednek gyümölcse, Jézus.<br />
					Asszonyunk, Szűz Mária, Istennek szent Anyja,<br />
					imádkozzál érettünk, bűnösökért,<br />
					most és halálunk óráján. Ámen.
				</Details>
				<Details title="Az Úrangyala (Angelus)">
					<span className="italic">
						A megtestesülés szent titkáról megemlékező imádságunk három versből (verzikulusból), 
						a hozzájuk kapcsolódó Üdvözlégyből, majd egy zárókönyörgésből áll. 
						A keresztény nép nagy áhítattal mondja a reggeli, déli és esti harangszóra. 
						A déli harangszó nekünk, magyaroknak különösen kedves: III. Kallixtusz pápa 
						rendelte el Hunyadi János nándorfehérvári győzelmének emlékére. 
						A Szent János evangéliumából vett harmadik versnél térdet hajtunk, 
						vagy mélyen meghajlunk.
					</span>
				
					<div className="border-b border-dark-yellow"></div>

					V: Az Úr angyala köszönté a Boldogságos Szűz Máriát, <br />
					és ő méhébe fogadá Szentlélektől szent Fiát.<br />
					Üdvözlégy, Mária...<br />
					<br />
					V: Íme az Úrnak szolgálóleánya, legyen nekem a te igéd szerint. <br />
					Üdvözlégy, Mária...<br />
					<br />
					V: És az Ige testté lőn, és miköztünk lakozék.<br />
					Üdvözlégy, Mária...<br />
					<br />
					Imádkozzál érettünk, Istennek szent Anyja,<br />
					hogy méltók lehessünk Krisztus ígéreteire.<br />
					Könyörögjünk! Kérünk téged, Úristen,<br />
					öntsd lelkünkbe szent kegyelmedet,<br />
					hogy akik az angyali üzenet által szent Fiadnak,<br />
					Jézus Krisztusnak megtestesülését megismertük,<br />
					az ő kínszenvedése és keresztje által<br />
					a feltámadás dicsőségébe vitessünk. 
					<br />Krisztus, a mi Urunk által. Ámen.
				</Details>
				<Details title="Reggeli ima">
					Szívem első gondolata,<br />
					Hozzád száll fel Istenem!<br />
					Te őriztél meg az éjjel,<br />
					Maradj ma is énvelem.<br />
					<br />
					Téged áldlak és imádlak,<br />
					Mint szerető gyermeked,<br />
					Szívem csakis azt akarja,<br />
					Ami kedves Teneked.<br />
					<br />
					Édes Jézus add kegyelmed,<br />
					S őrizz engem szüntelen,<br />
					Hogy egész nap Neked éljek,<br />
					Tiszta szívvel, bűntelen.<br />
					<br />
					Szűz Mária, Jézus Anyja,<br />
					Te mindnyájunk Anyja vagy.<br />
					Oltalmazz meg minden bajtól,Kísértésben el ne hagyj!<br />
					<br />
					Amen.
				</Details>
				<Details title="Esti ima">
					Ó, édes Istenem! Hálát rebeg lelkem,<br />
					Hogy egész napon át úgy szerettél engem.<br />
					<br />
					Bánom sok vétkemet, szent Fiadnak vére<br />
					Mossa meg kegyesen szívemet fehérre!<br />
					<br />
					Virrasszon felettem gondviselő szemed,<br />
					Kérlek, óvd ez éjjel testemet, lelkemet!<br />
					<br />
					Szűz Anyám s őrangyal, legyetek énvelem,<br />
					Ha ti rám vigyáztok, nyugodt lesz éjjelem.<br />
					<br />
					Amen.
				</Details>
			</div>
		</Content>
	);
}
 
export default Faith;