import '../fonts/gabriola/font.css';

const Content = (props) => {
	return (
		<div className="content gabriola mb-20">
			{props.children}
		</div>
	);
}
 
export default Content;