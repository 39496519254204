import '../fonts/MTCORSVA/font.css';

const WebsiteName = () => {
	return (
		<div className="sm:text-center mtcorsva text-dark-yellow text-xl sm:text-4xl xl:text-6xl font-bold col-span-2">
			Teskándi Római Katolikus Plébánia
		</div>
	);
}
 
export default WebsiteName;