const PUBLIC_URL = "https://teskandiplebania.hu/";

export const thumb_photos = [
	{
		src: PUBLIC_URL + "gallery/boncodfolde/Boncodfolde-1.jpg",
		width: 3,
		height: 2
	},
	{
		src: PUBLIC_URL + "gallery/boncodfolde/Boncodfolde-2.jpg",
		width: 3,
		height: 2
	},
  ];