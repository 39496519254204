import '../fonts/MTCORSVA/font.css';

const InfoBar = () => {
	return (
		<div className="grid grid-cols-3 gap-2 xl:gap-5 text-xl content-center place-items-center mtcorsva">
			<span className="info">Cím: 8983 Babosdöbréte, Hegyi utca 2.</span>
			<span className="info">Adószám: 19282886120</span>
			<span className="info">Telefon: 92/370-315, 30/548-8857</span>
		</div>
	);
}

export default InfoBar;