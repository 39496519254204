import '../fonts/gabriola/font.css';

const MenuLinkButton = (props) => {
	return (
		<a  className="basis-1/6 mb-0" href={props.href} rel="noopener noreferrer" target="_blank">
			<div className="nav-btn
				bg-gradient-to-b from-CUSTOM_YELLOW_BRIGHT via-CUSTOM_YELLOW_MED to-CUSTOM_YELLOW_DARK hover:bg-med-yellow
				hover:from-CUSTOM_YELLOW_MED hover:via-CUSTOM_YELLOW_DARK hover:to-CUSTOM_YELLOW_DARKEST
				text-2xl py-2 px-4 text-center cursor-pointer gabriola
			">
				{props.children}
			</div>
		</a>
	);

}
 
export default MenuLinkButton;