const PUBLIC_URL = "https://teskandiplebania.hu/";

export const thumb_photos = [
	{
		src: PUBLIC_URL + "gallery/babosdobrete/thumbnail_200/Babosdobrete-1.jpg",
		width: 2,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/babosdobrete/thumbnail_200/Babosdobrete-2.jpg",
		width: 2,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/babosdobrete/thumbnail_200/Babosdobrete-3.jpg",
		width: 2,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/babosdobrete/thumbnail_200/Babosdobrete-4.jpg",
		width: 3,
		height: 2
	},
	{
		src: PUBLIC_URL + "gallery/babosdobrete/thumbnail_200/Babosdobrete-5.jpg",
		width: 3,
		height: 2
	},
	{
		src: PUBLIC_URL + "gallery/babosdobrete/thumbnail_200/Babosdobrete-6.jpg",
		width: 3,
		height: 2
	},
	{
		src: PUBLIC_URL + "gallery/babosdobrete/thumbnail_200/Babosdobrete-7.jpg",
		width: 2,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/babosdobrete/thumbnail_200/Babosdobrete-8.jpg",
		width: 2,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/babosdobrete/thumbnail_200/Babosdobrete-9.jpg",
		width: 2,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/babosdobrete/thumbnail_200/Babosdobrete-10.jpg",
		width: 2,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/babosdobrete/thumbnail_200/Babosdobrete-11.jpg",
		width: 2,
		height: 3
	},
	{
		src: PUBLIC_URL + "gallery/babosdobrete/thumbnail_200/Babosdobrete-12.jpg",
		width: 3,
		height: 2
	},
	{
		src: PUBLIC_URL + "gallery/babosdobrete/thumbnail_200/Babosdobrete-13.jpg",
		width: 3,
		height: 2
	},
	{
		src: PUBLIC_URL + "gallery/babosdobrete/thumbnail_200/Babosdobrete-14.jpg",
		width: 3,
		height: 2
	}
  ];