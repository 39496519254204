import Content from '../components/content';
import ContentBlock from '../components/content-block';
import PageTitle from '../components/page-title';
import Text2xl from '../components/text2xl';


const Priest = () => {
	return (
		<Content>
			<PageTitle>Plébánosunk</PageTitle>
			<ContentBlock>
				<Text2xl>
					Szalai Attila vagyok, Zalaegerszegen születtem 1973. szeptember 23-án. Győri Czuczor Gergely Bencés Gimnáziumban érettségiztem 1992-ben.
					Szemináriumot Győrben végeztem. 1997-ben szenteltek pappá Szombathelyen. Öt évig voltam káplán a zalaegerszegi Mária Magdolna Plébánián.
					2002 augusztusában boncodföldi plébánosként négy községben teljesítettem szolgálatot. 2006 októberében vettem át a babosdöbrétei lelkészség
					vezetését is. 2007. augusztus 1-től teskándi plébánosként látom el az öt község szolgálatát.
				</Text2xl>
			</ContentBlock>
		</Content>
	);
}

export default Priest;