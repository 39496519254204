import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "./teskand_images";
import { thumb_photos } from "./teskand_images_thumb200";

import PageTitle from "../../components/page-title";
import Content from "../../components/content";
import ContentBlock from "../../components/content-block";
import Text2xl from "../../components/text2xl";

const Teskand = () => {
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);
	
	const openLightbox = useCallback((event, { photo, index }) => {
	  setCurrentImage(index);
	  setViewerIsOpen(true);
	}, []);
	
	const closeLightbox = () => {
	  setCurrentImage(0);
	  setViewerIsOpen(false);
	};

	const customStyles = {
		view: () => ({
			// none of react-images styles are passed to <View />
			position: 'relative',
			'& > img': {
				position: 'relative',
				margin: '0 auto'
			},
		})
	};

	return (
		<Content>
			<PageTitle>Teskánd</PageTitle>
			<ContentBlock>
				<Text2xl>
				<p>
					Zala megye északi részén, Zalaegerszeg szomszédságában található TESKÁND. A község a Zala folyó völgyében helyezkedik el, 
					a Szentmihályfai patak választja ketté. Az 1200-as évektől lelhetők fel írásos dokumentumok történetéről. Több szerből álló apró település volt, 
					lakói főként a környék gyenge minősége, agyagos termőföldjeinek műveléséből éltek. A múlt század második felétől jelentős ipari üzeme volt a téglagyár, 
					melyet a rendszerváltás után külföldi befektető vásárolt meg, majd az ezredforduló után bezárt. Napjainkban több egyéni és társas vállalkozás műküdik itt, 
					de a lakosság jelentős része a megyeszékhelyen dolgozik.
				</p>
				<p>
					Az 1970-es évektől indult komolyabb fejlődésnek a falu. Egyre több új ház, majd új utca épült, kedvező adottságait kihasználva sokan költöztek ide Zalaegerszegről. 
					Az utóbbi 30 évben lakóinak száma közel háromszorosára nőtt, 8 új utca és két lakópark épült illetve épül jelenleg is tovább.
					A község önálló iskolával rendelkezett, a 2. világháború után a felső tagozatot áthelyezték Andráshidára, itt csak alsó tagozatos oktatás folyt, 
					az e célra épített egyházi ingatlanban. Az új iskola 1987-ben kezdett épülni, és a 90-es évek elejétől körzeti általános iskolaként működik, 
					2007-ben vette fel a Csukás István Nevelési és Oktatási Gyermekközpont nevet, s magában foglalja a 2005-ben átadott új óvodát és az iskola épületében kialakított bölcsödét is.
					Óvoda a jelenlegi körjegyzőség épületében üzemelt 1977-től, majd az új iskola megépülését követően költözött át a Petőfi utcai épületbe, 
					ahol az 1940-es évek végétél katolikus kápolna is műküdött. Ezt a kápolnát az új óvoda elkészülte után bővítették ki. Méreteiben már általában alkalmas 
					funkciójának betöltésére, de az épület jellege, és állaga miatt csak rövid távon jelentett megoldást.
				</p>
				<p>
					Az új templom építése 2007-ben kezdődött, az alapkövet augusztus 5-én, Dr. Veres András megyéspüspük úr szentelte meg.
					Az időközben felépült templomot 2011 október 8-án szentelte fel Dr. Veres András megyés püspök. A templom védőszentje a Szent család lett.
				</p>
				</Text2xl>
			</ContentBlock>
			<br />
			<ContentBlock>
				<Gallery photos={thumb_photos} onClick={openLightbox} />
				<ModalGateway>
					{viewerIsOpen ? (
					<Modal onClose={closeLightbox}>
						<Carousel
						currentIndex={currentImage}
						styles={customStyles}
						views={photos.map(x => ({
							...x,
							srcset: x.srcSet,
							caption: x.title
						}))}
						/>
					</Modal>
					) : null}
				</ModalGateway>
			</ContentBlock>
		</Content>
	);
}
 
export default Teskand;