import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "./babosdobrete_images";
import { thumb_photos } from "./babosdobrete_images_thumb200";

import PageTitle from "../../components/page-title";
import Content from "../../components/content";
import ContentBlock from "../../components/content-block";
import Text2xl from "../../components/text2xl";

const Babosdobrete = () => {
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);
	
	const openLightbox = useCallback((event, { photo, index }) => {
	  setCurrentImage(index);
	  setViewerIsOpen(true);
	}, []);
	
	const closeLightbox = () => {
	  setCurrentImage(0);
	  setViewerIsOpen(false);
	};

	const customStyles = {
		view: () => ({
			// none of react-images styles are passed to <View />
			position: 'relative',
			'& > img': {
				position: 'relative',
				margin: '0 auto'
			},
		})
	};

	return (
		<Content>
			<PageTitle>Babosdöbréte</PageTitle>
			<ContentBlock>
				<Text2xl>
				<p>
					Legnagyobb patakja a Pálosfai-patak. Első említése már 1128-ból ismert. Jézus szíve római katolikus templomát 1933-34-ben építették. 
					A templom alatti kriptában a besenyői és velkei Skublics-család nyugszik, ők adták a területet a templomépítéshez. Itt található a felirat 
					szerint az "első magyar fegyvergyár felállítójának", Barthelmes Walter mérnöknek a síremlékei is.
					Észak-Göcseji-dombságban található Babosdöbréte, Zalaegerszegtől 15 km-re a dombok közt elterülő, patkó alakú falu. A falut Zalára jellemzően 
					szőlőhegyek övezik.
					A terület felszíne dombos, melyeket részben művelnek, részben erdő borítja. A dombokat több kisebb patak szeli fel, a legnagyobb a Pálosfai-patak. 
					A dombtetőn kialakult település régebben még több össze nem függő házcsoportból állt. Egységes falukép csak a 20. század elején kezdett kialakulni.
					Babosdöbréte több különálló településből áll, három fő része: Babosdöbréte, Rám, Kökényesmindszent.
					A templom a falu központjában áll. 1933-34-ben épült, melynek védőszentje Szent Imre. A templomban egy festmény látható Szent Imréről, 
					amelyet Szabó Ágnes festőnő készített a templomnak 1996-ban.
					A templom mögött egy kripta található. A kriptában nyugszik Barthelmes Walter és felesége, a néhai földbirtokos Skublics Cecília. 
					A feleség mint buzgó katolikus ajándékozta a templomtelket, az egész templomtető fa anyagát, a tégla fuvarozását részben ő végeztette.
					A férj, mint a sírfelirat hirdeti, az első magyar fegyvergyár felállítója volt. Bradfordban, a ködös Angliában született, és itt Göcsejben 
					nyugossza örök álmát (1858-1929). A fegyvergyár és fegyverei már régen a múlté, sírját csend lengi körül, és a sírfeliratot is kevesen olvassák 
					a falu lakóin kívül.
					A templomkertben található egy Mária szobor és a Hősi emlékmű, amelyet a két világháború áldozatainak emlékére állítottak.
					A templomhoz tartozik egy lelkészlak, amely 1967-ben épült.
					1983-ban a templomot kívülről tatarozták.
				</p>
				</Text2xl>
			</ContentBlock>
			<ContentBlock>
				<Gallery photos={thumb_photos} onClick={openLightbox} />
				<ModalGateway>
					{viewerIsOpen ? (
					<Modal onClose={closeLightbox}>
						<Carousel
						currentIndex={currentImage}
						styles={customStyles}
						views={photos.map(x => ({
							...x,
							srcset: x.srcSet,
							caption: x.title
						}))}
						/>
					</Modal>
					) : null}
				</ModalGateway>
			</ContentBlock>
		</Content>
	);
}
 
export default Babosdobrete;