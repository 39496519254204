const Col11 = (props) => {
	return (
		<div className="py-3 px-6 border-b border-dark-yellow grid grid-cols-2">
			<div className="">
				{props.name}
			</div>
			<div className="">
				{props.value}
			</div>
		</div>
	);
}
 
export default Col11;