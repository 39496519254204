const Col212 = (props) => {
	return (
		<div className="py-3 px-6 border-b border-dark-yellow grid grid-cols-5">
			<div className="col-span-2">
				{props.city}
			</div>
			<div className="">
				{props.time}
			</div>
			<div className="col-span-2">
				{props.desc}
			</div>
		</div>
	);
}
 
export default Col212;