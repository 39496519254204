import '../fonts/gabriola/font.css';

const MenuButtonDropdown = (props) => {
	return (
		<div className="dropdown basis-1/6 inline-block relative cursor-pointer ml-1">
			<div className="nav-btn
			bg-gradient-to-b from-CUSTOM_YELLOW_BRIGHT via-CUSTOM_YELLOW_MED to-CUSTOM_YELLOW_DARK
			text-2xl py-2 text-center w-full gabriola
			">
				{props.name}
			</div>
			<div className="dropdown-menu absolute hidden min-w-full">
				<div className="flex flex-col">
					{props.children}
				</div>
			</div>
		</div>
	);
}

export default MenuButtonDropdown;